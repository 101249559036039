import React, { useEffect, useState } from 'react';
import './PrivacyPolicy.css'; // Import the CSS for styling

const PrivacyPolicy = () => {
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    setFadeClass('fade-in'); // Apply fade-in effect when the component mounts
    return () => setFadeClass('fade-out'); // Apply fade-out effect when the component unmounts
  }, []);

  return (
    <div className={`privacy-policy-container ${fadeClass}`}>
      <h1 className="policy-header-title">Privacy Policy</h1>
      <div className="policy-content-box">
        <p>Your privacy is important to Impress Capital, INC (“Impress Capital”, “IC”, “we”, “us” and “our”). This Online Privacy Policy (“Policy”) explains how we collect, share, use, and protect information when you visit or use this online service and any other product or service offered by or through Impress Capital. This policy covers our online services, any product or service offered by or through us, as well as any interactions you may have while viewing content provided through one of Impress Capital’s digital advertising campaigns.</p>
        
        <h2>Information We Collect</h2>
        <p>When you visit or use our online services or obtain a product or service from us or through us, we may collect personal information from or about you such as your name, business name, email address, mailing address, telephone number(s), account numbers, username and password. We may also collect social security number, driver’s license number, financial information (such as bank statements, credit card statement, average bank balance, personal and business credit history, payment behavior, bank account information, tax identification number and beneficial ownership information) when you provide such information and where we believe it is reasonably required for ordinary business purposes.</p>

        <h2>Usage and Other Information</h2>
        <p>In addition to the personal information described above, we may collect certain information about your use of our online services. For example, we may capture the IP address of the device you use to connect to the online service, the type of operating system and browser you use, and information about the site you came from, the parts of our online service you access, and the site you visit next. We or our third-party partners may also use cookies, web beacons or other technologies to collect and store other information about your visit to, or use of, our online services. In addition, we may later associate the usage and other information we collect online with personal information about you.</p>

        <h2>Additional Sources of Information</h2>
        <p>We may also collect information about you from additional online and offline sources including from co-branded partner sites or commercially available third-party sources, such as credit reporting agencies. We may combine this information with the personal and other information we have collected about you under this Online Privacy Policy.</p>

        <h2>Use Of Information</h2>
        <p>We use the information that we collect about you or that you provide to us (including any personal information) in a number of ways, such as:</p>
        <ul>
          <li>To present our website and its content to you</li>
          <li>To process your applications and transactions</li>
          <li>To verify your identity and conduct appropriate due diligence</li>
          <li>To register you as a user and identify you when you sign into your account</li>
          <li>To prevent fraud and enhance the security of your account or our online services</li>
          <li>To respond to your requests and communicate with you</li>
          <li>To perform analytics concerning your use of our online services, including your responses to our emails and the pages and advertisements you view</li>
          <li>To provide you with tailored content and marketing messages</li>
          <li>To operate, evaluate and improve our business (including developing new products and services; improving existing products and services; performing data analytics; and performing accounting, auditing, and other internal functions)</li>
          <li>To comply with and enforce applicable legal requirements, relevant industry standards, contractual obligations and our policies</li>
          <li>For any other purposes that we may specifically disclose at the time you provide, or we collect your information</li>
          <li>For any other purpose with your consent</li>
        </ul>

        <h2>Privacy And Sharing Of Information</h2>
        <p>Impress Capital may share your personal information with third parties only in the ways that are described in this Policy and as permitted or required by law. We do not sell your personal information to third parties.</p>
        
        <p>We may share anonymous or aggregated information with third-parties to help deliver products, services, and content that are tailored to the users of our online services and for other purposes without restrictions.</p>
        
        <p>We may disclose personal information that we collect about you or that you provide to us in a number of ways, as described in this Policy. For example, we may share your personal information with:</p>
        <ul>
          <li>Impress Capital’s subsidiaries and affiliates</li>
          <li>Impress Capital’s bank partner(s)</li>
          <li>Impress Capital’s contractors, service providers and other third-parties that we use to support our business (and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which it was disclosed to them)</li>
        </ul>

        <h2>Data Security</h2>
        <p>Impress Capital takes your data security very seriously. We have implemented protocols designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>
        
        <p>Your information is securely sent to us with high-level encryption over the Transport Layer Security (TLS) protocol, meaning any connection between your browser and our servers is highly encrypted. When you enter personal information (such as your SSN or bank account number), that’s encrypted too.</p>

        <h2>Accessing/Updating/Correcting Your Information</h2>
        <p>Keeping your account information up-to-date is very important. To review or update certain account information, please email us at info@impresscapital.org</p>
        
        <h2>Do Not Track</h2>
        <p>Many web browsers possess a do-not-track feature that lets your browser tell websites you visit that you do not want to have your online activities tracked. Given that there is not a uniform way that browsers communicate the “Do Not Track” signal, Impress Capital does not currently interpret, respond to or alter its practices when it receives “Do Not Track” signals.</p>
        
        <h2>Understanding Cookies, Web Beacons And Other Tracking Technologies</h2>
        <p>We, or our service providers, and other companies we work with may deploy and use cookies, web beacons, local shared objects and other tracking technologies for various purposes, such as fraud prevention and monitoring our advertising and marketing campaign performance. Some of these tracking tools may detect characteristics or settings of the specific device you use to access our online services.</p>

        <p>Cookies or browser cookies are small amounts of data a website can send to a visitor’s web browser. They are often stored on the device you are using to help track your areas of interest. Cookies may also enable us or our service providers and other companies we work with to relate your use of our online services over time to customize your experience. Most web browsers allow you to adjust your browser settings to decline or delete cookies but doing so may degrade your experience with our online services.</p>
        
        <p>Clear GIFs, pixel tags, single pixel-gifs or web beacons (which are typically one-pixel, transparent images located on a webpage or in an email or other message) or similar technologies may be used on our sites and in some of our digital communications (such as email or other marketing messages). They may also be used when you are served advertisements, or you otherwise interact with advertisements outside of our online services. These are principally used to help recognize users, assess traffic patterns, and measure site or campaign engagement.</p>
        
        <p>Local Shared Objects, sometimes referred to as “flash cookies” may be stored on your hard drive using a media player or other software installed on your device. Local Shared Objects are similar to cookies in terms of their operation but may not be managed in your browser in the same way.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update this privacy policy from time to time to reflect changes in our practices, legal requirements, or for other operational, legal, or regulatory reasons.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;


