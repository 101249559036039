import React, { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './HomePage.css';

function HomePage() {
    useEffect(() => {
        // Disable scrolling when the component mounts
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="homepage-container">
            <div className="content">
                <div className="video-background">
                    <video autoPlay loop muted>
                        <source src="/promo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="headline-container">
                    <h2 className="headline-subtext animated-gradient">Making Lasting Impressions on Your Investments</h2>
                </div>
                {/* Transparent Container around the list */}
                <div className="transparent-list-container">
                    <ul className="overlay-list">
                        <li className="flex items-center justify-center list-item">
                            <FaCheckCircle className="text-green-500 mr-2 icon" />
                            APPROVALS WITHIN 2-4 HOURS
                        </li>
                        <li className="flex items-center justify-center list-item">
                            <FaCheckCircle className="text-green-500 mr-2 icon" />
                            FUNDING WITHIN 24 HOURS
                        </li>
                        <li className="flex items-center justify-center list-item">
                            <FaCheckCircle className="text-green-500 mr-2 icon" />
                            CUSTOMIZABLE AND FLEXIBLE APPROVALS
                        </li>
                        <li className="flex items-center justify-center list-item">
                            <FaCheckCircle className="text-green-500 mr-2 icon" />
                            TERMS UP TO 36 MONTHS
                        </li>
                        <li className="flex items-center justify-center list-item">
                            <FaCheckCircle className="text-green-500 mr-2 icon" />
                            98% APPROVAL RATE
                        </li>
                        <li className="flex items-center justify-center list-item">
                            <FaCheckCircle className="text-green-500 mr-2 icon" />
                            FUNDING UP TO 10 MILLION
                        </li>
                        <li className="flex items-center justify-center list-item">
                            <FaCheckCircle className="text-green-500 mr-2 icon" />
                            QUICK AND EASY APPLICATION PROCESS
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
