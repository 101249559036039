import React from 'react';
import logoDefault from '../assets/logo-default.png'; // Your default logo
import logoHover from '../assets/logo-hover.png'; // Your hover logo
import './Navigation.css';

function Navigation({ onNavigate }) {
    return (
        <nav className="navbar">
            <div className="navbar-logo-container" onClick={() => onNavigate('/')}>
                <img src={logoDefault} alt="Impress Capital INC Logo" className="logo-default" />
                <img src={logoHover} alt="Impress Capital INC Logo" className="logo-hover" />
            </div>
            <ul>
                <li><button onClick={() => onNavigate('/')} className="text-white font-bold text-lg hover:text-green-500 px-4">Home</button></li>
                <li><button onClick={() => onNavigate('/services')} className="text-white font-bold text-lg hover:text-green-500 px-4">Services</button></li>
                <li><button onClick={() => onNavigate('/about-us')} className="text-white font-bold text-lg hover:text-green-500 px-4">About Us</button></li>
                <li><button onClick={() => onNavigate('/partners')} className="text-white font-bold text-lg hover:text-green-500 px-4">Partners</button></li>
            </ul>
            <a href="https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=eeefef26-8fff-4786-b1a5-b14f187db06f&env=na4&acct=2d264f40-55bf-4fba-8b4e-a33a4f1fe72c&v=2" target="_blank" rel="noopener noreferrer" className="apply-button">
                <span className="apply-button-text">Apply Here!</span>
            </a>
        </nav>
    );
}

export default Navigation;
