import React from 'react';
import './PartnersPage.css';

function PartnersPage() {
    return (
        <div className="partners-page-container">
            <div className="video-background">
                <video autoPlay loop muted>
                    <source src="/videos/46284-446732347_medium.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="content-overlay">
                <h1 className="partners-header-title animated-gradient text-center my-4">Partner with Us</h1>
                <div className="content-box">
                    <p className="mb-4 p-4 bg-gray-100 rounded-md shadow-sm">
                        Join Impress Capital as an ISO partner to expand funding options for small business merchants with greater flexibility.
                        As a prominent lender in the industry, Impress Capital offers top-tier commissions to ISOs and ensures swift,
                        streamlined approvals for their merchants.
                    </p>
                    <p className="mb-4 p-4 bg-gray-100 rounded-md shadow-sm">
                        Choosing Impress Capital means choosing more than just a lender; it's choosing a supportive community dedicated
                        to your success. With us, you're not just a partner; you're valued and supported every step of the way.
                        Apply with us NOW! We're excited to welcome you to the Impress Capital family and help you grow your business.
                    </p>
                    <p className="p-4 bg-gray-100 rounded-md shadow-sm text-center">
                        For more information email us at <a href="mailto:Info@impresscapital.org" className="email-link">Info@impresscapital.org</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PartnersPage;