import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import ServicesPage from './components/ServicesPage';
import AboutUsPage from './components/AboutUsPage';
import PartnersPage from './components/PartnersPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import Navigation from './components/Navigation';
import Footer from './components/Footer'; // Ensure this import is correct
import './App.css';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    setFadeClass('fade-in');

    if (location.pathname === '/') {
      document.body.style.overflow = 'hidden'; // Disable scrolling for the homepage
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling for other pages
    }

    window.scrollTo(0, 0);
  }, [location]);

  const handleNavigation = (path) => {
    setFadeClass('fade-out');
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  return (
    <>
      <Navigation onNavigate={handleNavigation} />
      <div className={`content-container ${fadeClass}`}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
        </Routes>
      </div>
      <Footer onNavigate={handleNavigation} /> {/* Pass handleNavigation to Footer */}
    </>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}




