import React from 'react';
import './TermsOfUse.css'; // Import the CSS for styling

const TermsOfUse = () => {
  return (
    <div className="terms-of-use-container">
      <h1 className="policy-header-title">Terms of Use</h1>
      <div className="policy-content-box">
        <p>All applications submitted electronically shall have the same force and effect as if the application bore an inked original signature(s). The above information, together with any accompanying financial statements, schedules, or other materials, is submitted for the purpose of obtaining credit and is warranted to be true, correct, and complete.</p>
        
        <h2>US Patriot Act</h2>
        <p>To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person and business that seeks a business loan. What this means for you: When you apply for a loan, we will ask for your business name, address, and Tax Identification Number. We will also ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.</p>

        <h2>For Loan/Merchant Services</h2>
        <p>The Merchant and Owner(s)/Officer(s) identified in the application (individually, an “Applicant”) each represents, acknowledges, and agrees that:</p>
        <ul>
          <li>All information and documents provided to Impress Capital INC, including credit card processor statements, are true, accurate, and complete.</li>
          <li>Applicant will immediately notify Impress Capital of any change in such information or financial condition.</li>
          <li>Applicant authorizes Impress Capital to disclose all information and documents that Impress Capital may obtain, including credit reports, to other persons or entities (collectively, “Assignees”) that may be involved with or acquire commercial loans having daily repayment features and/or Merchant Cash Advance transactions, including without limitation the application therefor (collectively, “Transactions”) and each Assignee is authorized to use such information and documents, and share such information and documents with other Assignees, in connection with potential Transactions.</li>
          <li>Each Assignee will rely upon the accuracy and completeness of such information and documents.</li>
          <li>Impress Capital, Assignees, and each of their representatives, successors, assigns, and designees (collectively, “Recipients”) are authorized to request and receive any investigative reports, credit reports, statements from creditors or financial institutions, verification of information, or any other information that a Recipient deems necessary.</li>
          <li>Applicant waives and releases any claims against Recipients and any information-providers arising from any act or omission relating to the requesting, receiving, or release of information.</li>
          <li>Each Owner/Officer represents that he or she is authorized to sign this form on behalf of the Merchant.</li>
          <li>Applicant consents to receive direct mail, faxes, text messages, and emails sent by Impress Capital and its affiliates for the purposes of transmitting account updates, requests for information, and notices.</li>
          <li>This request is for business and not for consumer purposes.</li>
        </ul>

        <h2>For Alternate Funding Services</h2>
        <p>I hereby certify:</p>
        <ul>
          <li>The information provided is true and correct.</li>
          <li>You are hereby authorized to investigate all bank, credit, and trade references, and said references are hereby authorized to release any requested information to you or your nominee.</li>
          <li>Such authorization shall extend to obtaining a personal credit profile in considering this application and subsequently for the purposes of update, renewal, or extension of such credit or additional credit and for reviewing or collecting the resulting account.</li>
          <li>This information may be transmitted by us to you and by you to underwriter(s) for the purpose of granting me credit, either electronically or manually, and that by submitting this application, I take full responsibility for transmission thereof.</li>
          <li>I am over 18 years of age.</li>
          <li>I acknowledge my rights under the Fair Credit Reporting Act.</li>
          <li>I consent to receive direct mail, faxes, text messages, and emails sent by Impress Capital and its affiliates for the purposes of transmitting account updates, requests for information, and notices.</li>
          <li>This request is for business and not for consumer purposes.</li>
        </ul>

        <h2>Text Messaging</h2>
        <p>By providing my wireless phone number to Impress Capital INC, I agree and acknowledge that Impress Capital INC may send text and multimedia messages to my wireless phone number for any purpose. I agree that these text or multimedia messages may be regarding the products and/or services that I have previously purchased and products and/or services that Impress Capital INC may market to me. I acknowledge that this consent may be removed at my request but that until such consent is revoked, I may receive text or multimedia messages from Impress Capital INC.</p>

        <h2>Federal Equal Credit Opportunity Act</h2>
        <p>The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion, national origin, sex, marital status, or age (provided the applicant has the capacity to enter into a binding contract); because all or part of the applicant’s income derives from any public assistance program; or because the applicant has in good faith exercised any right under the Consumer Credit Protection Act. If for any reason your application for business credit is denied, you have the right to a written statement of the specific reasons for the denial.</p>

        <h2>Credit Report Authorization</h2>
        <p>Each of the undersigned, who is either a Principal, Sole Proprietor, or Personal Guarantor of the above-named business, recognizes that his or her individual credit history may be a factor in the evaluation of this application of the above-named business for funding. Each of the undersigned hereby authorizes Impress Capital INC (“IC”) and its assigns, representatives, and/or affiliate partners of IC to obtain his or her credit report (and any updates to his or her credit report) in connection with IC’s consideration of this application and any affiliate partners of IC in connection with any subsequent review of the account of the above-named business for the purpose of obtaining business financing. Each of the undersigned hereby authorizes IC to utilize this information as needed.</p>

        <h2>Governing Law</h2>
        <p>These terms and conditions are governed by the laws of the state where Impress Capital INC is located, without regard to its conflict of laws principles.</p>
      </div>
    </div>
  );
};

export default TermsOfUse;

