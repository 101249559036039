import React, { useEffect } from 'react';
import './AboutUsPage.css';

function AboutUsPage() {
    useEffect(() => {
        // Disable scrolling when the component mounts
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="aboutus-page-container">
            <div className="video-background">
                <video autoPlay loop muted>
                    <source src="/videos/5727833-uhd_3840_2160_30fps.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="content-overlay">
                <h1 className="aboutus-header-title animated-gradient text-center my-4">About Us</h1>
                <div className="content-box">
                    <p className="mb-4 p-4 bg-gray-100 rounded-md shadow-sm">
                        At <strong>Impress Capital</strong>, we're driven by a heartfelt mission: to grow your business with the financial support you deserve. 
                        Offering a broad range of products tailored to meet your unique needs, we're not just lenders; we're passionate partners dedicated 
                        to seeing you thrive in today's world. With us, you'll find personalized solutions, unwavering support, fast funding options, 
                        and a commitment to your success that sets us apart from the rest. Count on us to support your journey towards growth and future success within your business.
                    </p>
                    <p className="mb-4 p-4 bg-gray-100 rounded-md shadow-sm">
                        We eagerly anticipate building a long-lasting business relationship with you. We're committed to standing by your side and helping you grow 
                        every step of the way for the next 5-10 years. Unlike the everyday companies inundating your inbox, we're here to be more than just a transaction. 
                        Your success is our success, and we're excited to be part of your story. Let's start this journey together and turn your aspirations into achievements, 
                        and you’ll be left <strong>IMPRESSed</strong>!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutUsPage;
