import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import './ServicesPage.css';

function ServicesPage() {
    return (
        <div className="services-page-container">
            <div className="video-background">
                <video autoPlay loop muted>
                    <source src="/videos/mixkit-business-people-at-work-meeting-4809-hd-ready.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="content-overlay text-gray-800 p-8">
                <h1 className="services-header-title animated-gradient text-center my-4">Our Services</h1>
                <div className="max-w-4xl mx-auto space-y-4">
                    <div className="p-4 shadow-lg rounded-lg flex items-center justify-between content-box">
                        <div>
                            <h2 className="text-xl text-green-700 font-semibold">Revenue Based Funding</h2>
                            <ul className="list-none pl-5 services-page">
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>$10 Million amount funded</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>12 - 18 month term</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>Daily or weekly payment</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>Zero collateral</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>150% of gross monthly deposits</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>Same day funding available</span></li>
                            </ul>
                        </div>
                        <img src="/images/hand.png" alt="Hand Icon" className="service-icon" />
                    </div>
                    <div className="p-4 shadow-lg rounded-lg flex items-center justify-between content-box">
                        <div>
                            <h2 className="text-xl text-green-700 font-semibold">Traditional Term Loans</h2>
                            <ul className="list-none pl-5 services-page">
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>$500K loan amount</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>5 year terms</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>APR starting at 8%</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>Monthly payment</span></li>
                            </ul>
                        </div>
                        <img src="/images/bank.png" alt="Bank Icon" className="service-icon" />
                    </div>
                    <div className="p-4 shadow-lg rounded-lg flex items-center justify-between content-box">
                        <div>
                            <h2 className="text-xl text-green-700 font-semibold">SBA Loans</h2>
                            <ul className="list-none pl-5 services-page">
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>Up to $500k</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>10 year terms</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>Starting at 8% APR</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>Monthly payment</span></li>
                            </ul>
                        </div>
                        <img src="/images/stats.png" alt="Stats Icon" className="service-icon" />
                    </div>
                    <div className="p-4 shadow-lg rounded-lg flex items-center justify-between content-box">
                        <div>
                            <h2 className="text-xl text-green-700 font-semibold">Line of Credit</h2>
                            <ul className="list-none pl-5 services-page">
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>$250k Credit limit</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>True revolving line of credit</span></li>
                                <li className="flex items-center services-list-item"><FaCheckCircle className="text-green-500 mr-2 icon"/><span>36 month amortization</span></li>
                            </ul>
                        </div>
                        <img src="/images/money-bag.png" alt="Money Bag Icon" className="service-icon" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesPage;
